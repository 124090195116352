<template>
  <v-main>
    <v-row>
      <v-col cols="10" style="padding-top: 12px; padding-bottom: 30px; margin-top: 82px">
        <router-link to="/home" style="text-decoration: none"
          ><v-icon class="icon-home" style="margin-right: 16px;">mdi-home-outline</v-icon></router-link>
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <router-link to="/certidoes" class="text-link" style="margin-right: 16px;"> Certidões </router-link>
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <router-link to="/crf" style="text-decoration: none; color: #2D5796; margin-right: 16px;"
          >Certidões de Regularidade do FGTS</router-link
        >
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <strong class="text-link">Histórico</strong>
      </v-col>
      <v-col cols="2" style="padding-top: 12px; padding-bottom: 30px; margin-top: 82px">
        <router-link to="/crf" style="text-decoration: none; color: #2D5796">Voltar a página anterior</router-link>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="h6">
        <div style="width: 530px; height: 50px; display: inline-block">
          <h2 class="mt-2 mb-8 titleColor--text">Histórico da Certidão</h2>
        </div>
        <p style="width: 750px; color: #575767" class="subtitle">
          Certidão inserida por <strong> {{ criadoPor }} </strong> em <strong>{{ ultimaDataAtualizacao }} </strong> ás
          <strong> {{ horaAtualizacao }}</strong>
        </p>
      </v-col>
      <!-- <v-col cols="2">
        <div style="width: 530px; height: 50px; display: inline-block; padding-left: 495px">
            <v-btn color="success" @click="openModal()" :disabled="!isAdmin">
              + Adicionar
            </v-btn>
        </div>
      </v-col> -->
    </v-row>
    <v-card>
      <div id="crf-history">
        <v-data-table
          :headers="headers"
          :items="crfHistoricos"
          :items-per-page="10"
          :options.sync="options"
          :loading="myloadingvariable"
        >
          <template v-slot:item.empresa="{ item }">
            {{ item.empresa }}
          </template>

          <template v-slot:item.cnpj="{ item }">
            {{ item.cnpj | cnpj }}
          </template>

          <template v-slot:item.emissao="{ item }">
            {{ item.emissao | date }}
          </template>

          <template v-slot:item.validade="{ item }">
            {{ item.validade | date }}
          </template>

          <template v-slot:item.codigo="{ item }">
            {{ item.codigo }}
          </template>

          <template v-slot:item.situacao="{ item }">
            {{ item.situacao }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon class="mr-2 button-size" @click="downloadCertidao(item)">
              <v-icon small> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <modal-crf-nova ref="modalCrfNova" :crf="crfEdit" @refresh="refresh" />
  </v-main>
</template>

<script>
import crfService from '@/services/crfService.js';
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import { eventBus } from '../../../main.js';
import gedService from '@/services/gedService.js';

export default {
  name: 'CrfHistorico',
  components: {
    ModalCrfNova: () => import('./components/ModalCrfNova.vue'),
  },
  props: {
    idCrf: {
      required: true,
    },
  },

  data() {
    return {
      criadoPor: '',
      ultimaDataAtualizacao: null,
      horaAtualizacao: '',
      crfEdit: {},
      myloadingvariable: false,
      isAdmin: false,
      options: {},
      crfHistoricos: [],
      historicoCertidaoResponse: [],
      totalItems: 0,
      headers: [
        { text: 'Empresa', align: 'start', value: 'empresa', width: '250px' },
        { text: 'CNPJ', align: 'start', value: 'cnpj' },
        { text: 'Emissão', value: 'emissao' },
        { text: 'Validade', value: 'validade' },
        { text: 'Código', value: 'codigo' },
        { text: 'Situação', value: 'situacao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
      userInfo: 'user/userInfo',
    }),
  },
  watch: {
    options: {
      handler() {
        if (this.crfHistoricos && this.crfHistoricos.length === 0) this.fetch();
        else this.fetchDebounced();
      },
    },
  },

  created() {
    this.fetchDebounced = debounce(this.fetch, 300);
  },

  methods: {
    refresh() {
      this.crfEdit = {};
      this.fetch();
    },
    openModal() {
      this.$refs.modalCrfNova.$emit('open');
    },
    async fetch() {
      let adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');
      if (adminOrNot.length > 0) {
        this.isAdmin = true;
      }

      this.myloadingvariable = true;
      let response = await crfService.crf.getCrfHistorico(this.idCrf);
      this.crfHistoricos = response.data;

      if (this.crfHistoricos.length > 0) {
        this.criadoPor = this.crfHistoricos[this.crfHistoricos.length - 1].criadoPor;
        if (this.criadoPor === 'crf-api-user') {
          this.criadoPor = 'automaticamente';
        }
        let dataAtualizacao = this.crfHistoricos[this.crfHistoricos.length - 1].dataCriacao;
        let data = dataAtualizacao.split('-');
        let ultimaDataAtualizacao = `${data[2].substring(0, 2)}/${data[1]}/${data[0]}`;
        this.horaAtualizacao = data[2].substring(3, 11);
        this.ultimaDataAtualizacao = ultimaDataAtualizacao;
      }

      this.myloadingvariable = false;
    },
    // async downloadCertidao(item) {
    //   const linkSource = 'data:application/pdf;base64,' + item.pdf;
    //   const downloadLink = document.createElement('a');
    //   downloadLink.href = linkSource;
    //   this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    //   downloadLink.download = 'certificado_crf.pdf';
    //   downloadLink.click();
    // },

    async downloadCertidao(item) {

      this.downloading = true;

      let response = await gedService.files.get(item.idArquivoGED);
      let fileName = `certificado_crf.pdf`;
      this.downloadDocument(response, fileName);

      // const linkSource = 'data:application/pdf;base64,' + item.pdf;
      // const downloadLink = document.createElement('a');
      // downloadLink.href = linkSource;
      // downloadLink.download = 'certificado_crf.pdf';
      // downloadLink.click();
      // this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/octet-stream;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base) !important;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-label {
  color: var(--v-primary-base);
}

::v-deep .v-label--active {
  color: #575767 !important;
}

.icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

.secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}
::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}
.secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  color: #fff !important;
  background-color: var(--v-primary-base) !important;
}

::v-deep #textPicker:disabled {
  color: var(--v-primary-base);
  background-color: #e7e7fa !important;
}
::v-deep #textPicker {
  color: var(--v-primary-base) !important;
}
.btn-exportar-enabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.btn-exportar-disabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background-color: #e7e7fa !important;
  color: #8d8d99 !important;
}
#date-hint-div {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -7%;
}

#date-hint-div p {
  bottom: 3px;
}

#date-hint-div.v-messages__message {
  color: #962d33;
}

.title {
  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}

.subtitle {
  height: 24px;
  width: 921px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
}
.subtitle-link {
  float: right;
  color: #575767;
}
.subtitle-link a {
  text-decoration: none;
}

.btn-config {
  height: 48px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base);
}
.text-link {
  text-decoration: none;
  color: #8d8d99 !important;
}
</style>
