var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticStyle: {
                "padding-top": "12px",
                "padding-bottom": "30px",
                "margin-top": "82px",
              },
              attrs: { cols: "10" },
            },
            [
              _c(
                "router-link",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/home" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "icon-home",
                      staticStyle: { "margin-right": "16px" },
                    },
                    [_vm._v("mdi-home-outline")]
                  ),
                ],
                1
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "text-link",
                  staticStyle: { "margin-right": "16px" },
                  attrs: { to: "/certidoes" },
                },
                [_vm._v(" Certidões ")]
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c(
                "router-link",
                {
                  staticStyle: {
                    "text-decoration": "none",
                    color: "#2D5796",
                    "margin-right": "16px",
                  },
                  attrs: { to: "/crf" },
                },
                [_vm._v("Certidões de Regularidade do FGTS")]
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c("strong", { staticClass: "text-link" }, [_vm._v("Histórico")]),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: {
                "padding-top": "12px",
                "padding-bottom": "30px",
                "margin-top": "82px",
              },
              attrs: { cols: "2" },
            },
            [
              _c(
                "router-link",
                {
                  staticStyle: { "text-decoration": "none", color: "#2D5796" },
                  attrs: { to: "/crf" },
                },
                [_vm._v("Voltar a página anterior")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "h6", attrs: { cols: "6" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "530px",
                  height: "50px",
                  display: "inline-block",
                },
              },
              [
                _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
                  _vm._v("Histórico da Certidão"),
                ]),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "subtitle",
                staticStyle: { width: "750px", color: "#575767" },
              },
              [
                _vm._v(" Certidão inserida por "),
                _c("strong", [_vm._v(" " + _vm._s(_vm.criadoPor) + " ")]),
                _vm._v(" em "),
                _c("strong", [_vm._v(_vm._s(_vm.ultimaDataAtualizacao) + " ")]),
                _vm._v(" ás "),
                _c("strong", [_vm._v(" " + _vm._s(_vm.horaAtualizacao))]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("v-card", [
        _c(
          "div",
          { attrs: { id: "crf-history" } },
          [
            _c("v-data-table", {
              attrs: {
                headers: _vm.headers,
                items: _vm.crfHistoricos,
                "items-per-page": 10,
                options: _vm.options,
                loading: _vm.myloadingvariable,
              },
              on: {
                "update:options": function ($event) {
                  _vm.options = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "item.empresa",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.empresa) + " ")]
                  },
                },
                {
                  key: "item.cnpj",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                    ]
                  },
                },
                {
                  key: "item.emissao",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("date")(item.emissao)) + " "),
                    ]
                  },
                },
                {
                  key: "item.validade",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("date")(item.validade)) + " "),
                    ]
                  },
                },
                {
                  key: "item.codigo",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.codigo) + " ")]
                  },
                },
                {
                  key: "item.situacao",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.situacao) + " ")]
                  },
                },
                {
                  key: "item.actions",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 button-size",
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadCertidao(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-download "),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("modal-crf-nova", {
        ref: "modalCrfNova",
        attrs: { crf: _vm.crfEdit },
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }